import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-tabata-timer',
  templateUrl: './tabata-timer.component.html',
  styleUrls: ['./tabata-timer.component.scss'],
})
export class TabataTimerComponent implements OnInit, OnDestroy {

  totalRounds: number = 0;
  currentRound: number = 1;
  totalTimeInSeconds: number = 0;
  exerciseTimeInSeconds: number = 0; // Default exercise duration in seconds
  restTimeInSeconds: number = 0; // Default rest duration in seconds
  timeInSeconds: number;
  timer: any;
  displayTime: string;
  inputRounds: number;
  inputExerciseDuration: number;
  inputRestDuration: number;
  timerStarted: boolean = false;
  countdown: number = 10; // Initial countdown in seconds
  exercisePhase: boolean = true; // True for exercise, false for rest
  tabataStarted: boolean = false; // New property to track Tabata timer start

  @Output() timeFinished = new EventEmitter();

  constructor(
    private generalService: GeneralService
  ) {}

  ngOnInit() {}

  validateInput(): boolean {
    return (
      this.inputRounds > 0 &&
      this.inputExerciseDuration > 0 &&
      this.inputRestDuration > 0
    );
  }

  startCountdown() {
    this.timerStarted = true;
    this.tabataStarted = false; // Set tabataStarted to false (hide Tabata info during countdown)
    this.timer = setInterval(async () => {
      if (this.countdown >= 0) {
        await this.generalService.shouldPlayAudio(this.countdown);

        this.displayTime = this.countdown.toString();
        this.countdown--;
        this.timeInSeconds = this.countdown * 10;
        this.exerciseTimeInSeconds = this.inputExerciseDuration;
      } else {
        clearInterval(this.timer);
        this.startTimer();
      }
    }, 1000);
  }

  startTimer() {
    if (this.validateInput()) {
      this.totalRounds = this.inputRounds;
      this.exerciseTimeInSeconds = this.inputExerciseDuration;
      this.restTimeInSeconds = this.inputRestDuration;
      this.calculateTotalTime();
      this.timerStarted = true;
      this.tabataStarted = true; // Set tabataStarted to true (show Tabata info)
      this.displayTime = this.exercisePhase
        ? this.getFormattedTime(this.exerciseTimeInSeconds)
        : this.getFormattedTime(this.restTimeInSeconds);
      this.initTimer();
    } else {
      alert('Please enter valid values for rounds, exercise duration, and rest duration.');
    }
  }

  calculateTotalTime() {
    // Total time for one round (exercise + rest)
    const oneRoundTime = this.exerciseTimeInSeconds + this.restTimeInSeconds;
    // Total time for all rounds
    this.totalTimeInSeconds = oneRoundTime * this.totalRounds;
  }

  initTimer() {
    this.timeInSeconds = this.exercisePhase
      ? this.exerciseTimeInSeconds
      : this.restTimeInSeconds;
    this.timer = setInterval(() => {
      this.updateTime();
    }, 1000);
  }

  async updateTime() {
    if (this.timeInSeconds > 0) {
      this.timeInSeconds--;
      this.displayTime = this.getSecondsAsDigitalClock(this.timeInSeconds);

      await this.generalService.shouldPlayAudio(this.timeInSeconds);

      if (this.timeInSeconds === 0) {
        this.switchPhase();
        this.startNextInterval();
      }
    }
  }

  switchPhase() {
    this.exercisePhase = !this.exercisePhase;
  }

  startNextInterval() {
    // Move to the next round during exercise phase
    if (this.exercisePhase) {
      if (this.currentRound < this.totalRounds) {
        this.currentRound++;
      } else {
        // Timer completed, you can add logic here
        clearInterval(this.timer);
        this.timeFinished.emit();
      }
    }

    // Set the display and timer for the next phase
    this.displayTime = this.exercisePhase
      ? this.getFormattedTime(this.exerciseTimeInSeconds)
      : this.getFormattedTime(this.restTimeInSeconds);
    this.timeInSeconds = this.exercisePhase
      ? this.exerciseTimeInSeconds
      : this.restTimeInSeconds;
  }

  getSecondsAsDigitalClock(inputSeconds: number) {
    const secNum = parseInt(inputSeconds.toString(), 10);
    const hours = Math.floor(secNum / 3600);
    const minutes = Math.floor((secNum - hours * 3600) / 60);
    const seconds = secNum - hours * 3600 - minutes * 60;

    const hoursString = hours > 0 ? this.padNumber(hours) + ':' : '';
    return hoursString + this.padNumber(minutes) + ':' + this.padNumber(seconds);
  }

  padNumber(num: number) {
    return num < 10 ? '0' + num : num;
  }

  getFormattedTime(inputSeconds: number): string {
    const minutes = Math.floor(inputSeconds / 60);
    const seconds = inputSeconds % 60;
    return `${this.padNumber(minutes)}:${this.padNumber(seconds)}`;
  }

  getProgressPercentage(): number {
    return (this.timeInSeconds / (this.exerciseTimeInSeconds)) * 100;
  }

  finish() {
    clearInterval(this.timer);
    this.timeFinished.emit();
  }

  ngOnDestroy() {
    clearInterval(this.timer);
  }
}
