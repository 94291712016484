import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-for-time',
  templateUrl: './for-time.component.html',
  styleUrls: ['./for-time.component.scss'],
})
export class ForTimeComponent implements OnInit {

  totalTimeInSeconds: number = 0;
  timeInSeconds: number;
  initialCountdown: number = 10; // Initial countdown in seconds
  countdown: number;
  timer: any;
  displayTime: string;
  inputMinutes: number;
  timerStarted: boolean = false;
  forTimeStarted: boolean = false;

  @Output() timeFinished = new EventEmitter();

  constructor(
    private generalService: GeneralService
  ) {}

  ngOnInit() {}

  startTimer() {
    if (this.inputMinutes && this.inputMinutes > 0) {
      this.totalTimeInSeconds = this.inputMinutes * 60;
      this.countdown = this.initialCountdown;
      this.timerStarted = true;
      this.startCountdown();
    } else {
      alert('Please enter a valid duration in minutes.');
    }
  }

  startCountdown() {
    this.timerStarted = true;
    this.forTimeStarted = false;

    this.timer = setInterval(async () => {
      if (this.countdown > 0) {
        await this.generalService.shouldPlayAudio(this.countdown);

        this.displayTime = this.countdown.toString();
        this.countdown--;
        this.timeInSeconds = this.countdown * 5;
      } else {
        this.displayTime = '0';
        await this.generalService.shouldPlayAudio(this.timeInSeconds);
        clearInterval(this.timer);
        this.initTimer();
      }
    }, 1000);
  }

  initTimer() {
    // this.timeInSeconds = this.totalTimeInSeconds;
    this.timer = setInterval(() => {
      this.updateTime();
    }, 1000);
  }

  async updateTime() {
    if (this.timeInSeconds < this.totalTimeInSeconds) {
      this.forTimeStarted = true;
      this.timeInSeconds++;
      this.displayTime = this.getSecondsAsDigitalClock(this.timeInSeconds);
      await this.generalService.shouldPlayAudio(this.timeInSeconds);
    } else {
      clearInterval(this.timer);
      // Timer completed, you can add logic here
      this.timeFinished.emit();
    }
  }

  getSecondsAsDigitalClock(inputSeconds: number) {
    const secNum = parseInt(inputSeconds.toString(), 10);
    const hours = Math.floor(secNum / 3600);
    const minutes = Math.floor((secNum - hours * 3600) / 60);
    const seconds = secNum - hours * 3600 - minutes * 60;

    const hoursString = hours > 0 ? this.padNumber(hours) + ':' : '';
    return hoursString + this.padNumber(minutes) + ':' + this.padNumber(seconds);
  }

  padNumber(num: number) {
    return num < 10 ? '0' + num : num;
  }

  getProgressPercentage(): number {
    const percentage = (this.timeInSeconds / (this.inputMinutes * 60)) * 100;

    return percentage;
  }

  finish() {
    clearInterval(this.timer);
    this.timeFinished.emit();
  }

  ngOnDestroy() {
    clearInterval(this.timer);
  }

}
