import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';

import { FullCalendarModule } from '@fullcalendar/angular';
import { EditorModule } from '@tinymce/tinymce-angular';
// import { AvatarModule } from 'ngx-avatar';

import { CircularProgressComponent } from './circular-progress/circular-progress.component';
import { TabataTimerComponent } from './tabata-timer/tabata-timer.component';
import { AmrapTimerComponent } from './amrap-timer/amrap-timer.component';
import { EmomTimerComponent } from './emom-timer/emom-timer.component';
import { ForTimeComponent } from './for-time/for-time.component';

import { PipesModule } from '../pipes/pipes.module';

import localeEs from '@angular/common/locales/es';

registerLocaleData(localeEs);

const declarationsAndExports = [
  ForTimeComponent,
  EmomTimerComponent,
  AmrapTimerComponent,
  TabataTimerComponent,
  CircularProgressComponent,
];

@NgModule({
  declarations: [
    ...declarationsAndExports
  ],
  exports: [
    ...declarationsAndExports
  ],
  imports: [
    // AvatarModule,
    CommonModule,
    IonicModule,
    EditorModule,
    FormsModule,
    PipesModule,
    // NgCalendarModule,
    FullCalendarModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ComponentsModule { }
