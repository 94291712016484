import { environment } from 'src/environments/environment';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { LoadingController } from '@ionic/angular';

import { PhotoViewer } from '@awesome-cordova-plugins/photo-viewer/ngx';
import { File, FileEntry } from '@awesome-cordova-plugins/file/ngx';

import { AlertService } from './alert.service';

// const API = environment.api;

@Injectable({
  providedIn: 'root'
})
export class GeneralService {

  private loading;

  @Output() unpaid: EventEmitter<any> = new EventEmitter();
  @Output() removeImage: EventEmitter<any> = new EventEmitter();
  @Output() limpiarImagenes: EventEmitter<any> = new EventEmitter();

  constructor(
    private loadingController: LoadingController,
    private alertService: AlertService,
    private photoViewer: PhotoViewer,
    private file: File,
  ) { }

  createQueue(tasks, callback) {
    let taskIndex = 0;

    return new Promise(done => {
      const handleResult = result => {
        taskIndex++;
        getNextTask();
      };
      const getNextTask = async () => {
        if (taskIndex < tasks.length) {
          const element = tasks[taskIndex];
          const response = await callback(element);
          handleResult(response);
        } else {
          done({ success: true });
        }
      };
      getNextTask();
    });
  }

  async delay(time = 5000) {
    return await new Promise((resolve, resject) => setTimeout(() => resolve(time), time));
  }

  previewImage(url) {
    this.photoViewer.show(url);
  }

  playAudio(elementId) {
    const audio: any = document.getElementById(elementId);
    if (audio) {
      audio.play();
    }
  }

  async shouldPlayAudio(time) {
    if ([1, 2, 3].includes(time)) {
      this.playAudio('countdownSound');
    } else if (time === 0) {
      this.playAudio('countdownSoundLarge');
    }
  }

  async showLoading(message = 'Cargando...') {
    this.loading = await this.loadingController.create({
      message
    });

    await this.loading.present();
  }

  hideLoading() {
    try { this.loading.dismiss(); }
    catch {}
  }

  b64toBlob(b64Data, contentType = '', sliceSize = 512) {
    const byteCharacters = atob(b64Data.replace(/^data:image\/(png|jpeg|jpg);base64,/, ''));
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  resolveLocalImageFile(path) {
    return new Promise(async (resolve) => {
      await this.file.resolveLocalFilesystemUrl(path)
      .then(entry => {
        (entry as FileEntry).file(async (file) => {
          const blob = await this.readImage(file);
          return resolve(blob);
        });
      })
      .catch(err => {
        console.log('err: ', err);
        this.alertService.toast('Ha ocurrido un error al leer el archivo.', 3000);
        return resolve(null);
      });
    });
  }

  getFileReader(): FileReader {
    const fileReader = new FileReader();
    const zoneOriginalInstance = (fileReader as any)["__zone_symbol__originalInstance"];
    return zoneOriginalInstance || fileReader;
  }

  private async readImage(file: any) {
    return new Promise(resolve => {
      const reader = this.getFileReader();
      reader.onloadend = () => {
        const blobImg = new Blob([reader.result], { type: file.type });
        return resolve(blobImg);
      };
      reader.readAsArrayBuffer(file);
    });
  }

  toggleFullScreen(element) {
    const doc = window.document as any;

    const requestFullScreen =
      element.requestFullscreen ||
      element.mozRequestFullScreen ||
      element.webkitRequestFullScreen ||
      element.msRequestFullscreen;

    const cancelFullScreen =
      doc.exitFullscreen ||
      doc.mozCancelFullScreen ||
      doc.webkitExitFullscreen ||
      doc.msExitFullscreen;

    if (
      !doc.fullscreenElement &&
      !doc.mozFullScreenElement &&
      !doc.webkitFullscreenElement &&
      !doc.msFullscreenElement
    ) {
      requestFullScreen.call(element);
    } else {
      cancelFullScreen.call(doc);
    }
  }

}
